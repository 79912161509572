import React from "react";
import { makeStyles } from "@material-ui/core";
import { useState, useLayoutEffect } from "react";
import { Navbar } from "../components/Navbar";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    justifyContent: "center",
    // alignItems: "center",
    // marginRight: "15%",
    height: "100%",
    padding: "3% 15% 0%",
    marginTop: "64px",
    flexDirection: "column",
  },
  orderDetailss: {
    width: "100%",
  },
  orderDetailsstxt: {},
  line: {
    borderBottom: "1px solid black",
  },
  // productDis: {
  //   display: "flex",
  //   justifyContent: "center",
  //   flexDirection: "column",
  //   // alignItems: "center",
  // },
  productImage: {
    // width: "fit-content",
    display: "flex",
    justifyContent: "center",

    marginTop: "10%",
  },
  table: {
    border: "1px solid black",
  },
  productDisMain: {
    display: "flex",
    // justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
  },
  productDisText: {
    display: "flex",
    justifyContent: "flex-start",
  },
  mainStatus: {
    display: "flex",
    gap: "2%",
    alignItems: "center",
  },
  progressStatus: {
    marginTop: "3%",
    marginLeft: "2%",
  },
  transist: {
    backgroundColor: "#49C06B",
    borderBottom: "1px solid black",
    display: "flex",
    flexDirection: "row",
    gap: "2%",
  },
  transistPC: {
    marginLeft: "1.2%",
  },
  transistTxt: {
    color: "white",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    // alignItems: "center",
    width: "100%",
  },
  transistTxtT: {
    color: "white",
    display: "flex",
    flexDirection: "column",
    // justifyContent: "flex-start",
    alignItems: "start",
    width: "fit-content",
  },
  nextProcess: {
    marginLeft: "2.07%",
    marginBottom: "2%",
  },
  nextProcessTxt: {
    display: "flex",
    gap: "3px",
  },
  nextProcessWrapper: {
    display: "flex",
    gap: "2%",
    alignItems: "center",
  },
  btnGroup: {
    width: "100%",
    marginTop: "1%",
    display: "flex",
    flexDirection: "row",
    gap: "1.2%",
    justifyContent: "center",
  },
  btnset1: {
    width: "49%",
    padding: "2%",
    backgroundColor: "white",
    // outline: "none",
    border: "1px solid black",
    boxShadow: "none",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "1.2%",
  },
  addressWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    width: "100%",
    height: "100%",
    marginTop: "2rem",
  },
}));

const OrderDetailss = () => {
  const classes = useStyles();
  return (
    <div>
      <Navbar />
      <div className={classes.wrapper}>
        <div className={classes.orderDetailss}>
          <div className={classes.orderDetailsstxt}>Order Detailss</div>
          <div className={classes.line}></div>
          <div>
            <div className={classes.productImage}>
              <img
                src="https://res.cloudinary.com/ayshubham/image/upload/v1649773326/Rectangle_785_vaecyo.png"
                alt=""
              />
            </div>
            <div className={classes.productDisMain}>
              <div style={{ marginBottom: "5%" }}>
                <div className={classes.productDisText}>Floral graphic t-shirt</div>
                <div>by Sness co.</div>
                <div>SIZE : XS QTY : 1</div>
              </div>
            </div>
          </div>
        </div>
        <div style={{ marginBottom: "2%" }}>order ID #120920912</div>

        <div className={classes.table}>
          <div style={{ borderBottom: "1px solid black" }}>
            <div className={classes.mainStatus}>
              <div className={classes.progressStatus}>
                <svg
                  width="15"
                  height="41"
                  viewBox="0 0 15 41"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="7.5" cy="7.5" r="7.5" fill="#EAEAEA" />
                  <line
                    x1="7.20313"
                    y1="40.0018"
                    x2="7.20312"
                    y2="17.9903"
                    stroke="#EAEAEA"
                    stroke-width="4"
                    stroke-dasharray="3 3"
                  />
                </svg>
              </div>
              <div>Arriving by mon 28 mar</div>
            </div>
          </div>
          <div className={classes.transist}>
            <div className={classes.transistPC}>
              <svg
                width="30"
                height="69"
                viewBox="0 0 30 69"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <line
                  x1="15"
                  y1="17"
                  x2="15"
                  stroke="#65DA86"
                  stroke-width="4"
                  stroke-dasharray="3 3"
                />
                <line
                  x1="14"
                  y1="69"
                  x2="14"
                  y2="49"
                  stroke="#65DA86"
                  stroke-width="4"
                  stroke-dasharray="3 3"
                />
                <circle cx="15" cy="33" r="15" fill="white" />
                <path
                  d="M23.5877 26.4341C23.4686 26.2965 23.327 26.1874 23.1709 26.1129C23.0148 26.0384 22.8474 26 22.6783 26C22.5092 26 22.3418 26.0384 22.1858 26.1129C22.0297 26.1874 21.888 26.2965 21.769 26.4341L12.2271 37.3815L8.21822 32.7736C8.0946 32.6368 7.94867 32.5292 7.78875 32.457C7.62884 32.3848 7.45808 32.3494 7.28622 32.3528C7.11437 32.3562 6.94478 32.3983 6.78714 32.4768C6.6295 32.5553 6.48691 32.6686 6.36749 32.8103C6.24807 32.9519 6.15417 33.1191 6.09115 33.3024C6.02813 33.4856 5.99722 33.6812 6.0002 33.8781C6.00317 34.075 6.03996 34.2694 6.10848 34.45C6.17699 34.6306 6.27588 34.794 6.39951 34.9308L11.3177 40.5659C11.4368 40.7035 11.5785 40.8126 11.7345 40.8871C11.8906 40.9616 12.058 41 12.2271 41C12.3962 41 12.5636 40.9616 12.7197 40.8871C12.8757 40.8126 13.0174 40.7035 13.1364 40.5659L23.5877 28.5913C23.7177 28.4539 23.8214 28.2871 23.8924 28.1014C23.9634 27.9158 24 27.7153 24 27.5127C24 27.31 23.9634 27.1096 23.8924 26.9239C23.8214 26.7383 23.7177 26.5715 23.5877 26.4341Z"
                  fill="#49C06B"
                />
              </svg>
            </div>
            <div className={classes.transistTxt}>
              <div className={classes.transistTxtT}>
                <div>In Transist</div>
                <div>items has reached Mumbai transport hub on 24 mar</div>
              </div>
            </div>
          </div>
          <div className={classes.nextProcessWrapper}>
            <div className={classes.nextProcess}>
              <svg
                width="15"
                height="41"
                viewBox="0 0 15 41"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <line x1="5.70312" y1="25.0016" x2="5.70312" y2="0.00158691" stroke="#333333" />
                <line x1="8.70312" y1="25.0016" x2="8.70312" y2="0.00158691" stroke="#333333" />
                <circle cx="7.5" cy="33.5" r="7.5" fill="#49C06B" />
              </svg>
            </div>
            <div className={classes.nextProcessTxt}>
              Order placed on
              <div style={{ color: "#B6AEAE" }}>23 march</div>
            </div>
          </div>
        </div>
        <div className={classes.btnGroup}>
          <button className={classes.btnset1}>
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.7309 7.27026L7.27148 10.7297"
                stroke="#333333"
                stroke-width="2"
                stroke-linecap="round"
              />
              <path
                d="M10.7309 10.7297L7.27148 7.27026"
                stroke="#333333"
                stroke-width="2"
                stroke-linecap="round"
              />
              <path
                d="M17 9C17 4.58172 13.4183 1 9 1C4.58172 1 1 4.58172 1 9C1 13.4183 4.58172 17 9 17C13.4183 17 17 13.4183 17 9Z"
                stroke="#333333"
                stroke-width="2"
              />
            </svg>
            cancel order
          </button>
          <button className={classes.btnset1}>
            <svg
              width="16"
              height="18"
              viewBox="0 0 16 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8 0C5.87827 0 3.84344 0.758567 2.34315 2.10882C0.842855 3.45908 0 5.29042 0 7.19998C0 12.06 7.05 17.5499 7.35 17.7839C7.53113 17.9234 7.76165 18 8 18C8.23835 18 8.46887 17.9234 8.65 17.7839C9 17.5499 16 12.06 16 7.19998C16 5.29042 15.1571 3.45908 13.6569 2.10882C12.1566 0.758567 10.1217 0 8 0ZM8 15.8849C5.87 14.085 2 10.206 2 7.19998C2 5.76781 2.63214 4.39431 3.75736 3.38161C4.88258 2.36892 6.4087 1.79999 8 1.79999C9.5913 1.79999 11.1174 2.36892 12.2426 3.38161C13.3679 4.39431 14 5.76781 14 7.19998C14 10.206 10.13 14.094 8 15.8849ZM8 3.59999C7.20887 3.59999 6.43552 3.81112 5.77772 4.2067C5.11992 4.60227 4.60723 5.16451 4.30448 5.82232C4.00173 6.48013 3.92252 7.20397 4.07686 7.9023C4.2312 8.60063 4.61216 9.24209 5.17157 9.74555C5.73098 10.249 6.44371 10.5919 7.21964 10.7308C7.99556 10.8697 8.79983 10.7984 9.53073 10.5259C10.2616 10.2535 10.8864 9.79204 11.3259 9.20002C11.7654 8.60801 12 7.91199 12 7.19998C12 6.2452 11.5786 5.32953 10.8284 4.6544C10.0783 3.97927 9.06087 3.59999 8 3.59999ZM8 8.99997C7.60444 8.99997 7.21776 8.8944 6.88886 8.69662C6.55996 8.49883 6.30362 8.21771 6.15224 7.8888C6.00087 7.5599 5.96126 7.19798 6.03843 6.84881C6.1156 6.49965 6.30608 6.17892 6.58579 5.92719C6.86549 5.67546 7.22186 5.50402 7.60982 5.43457C7.99778 5.36512 8.39991 5.40076 8.76537 5.537C9.13082 5.67324 9.44318 5.90395 9.66294 6.19995C9.8827 6.49596 10 6.84397 10 7.19998C10 7.67736 9.78929 8.1352 9.41421 8.47276C9.03914 8.81033 8.53043 8.99997 8 8.99997Z"
                fill="#333333"
              />
            </svg>
            track order
          </button>
        </div>
        <div className={classes.addressWrapper}>
          <div>Delivery Adderess</div>
          <div>
            {" "}
            <div>name - phone number</div>
            <div>address here</div>
          </div>
        </div>

        {/* <div>button change delivery address</div> */}
        {/* <div>paymebnt method</div> */}
      </div>
    </div>
  );
};

export default OrderDetailss;

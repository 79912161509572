import { Typography, Button, RadioGroup, FormControlLabel, Radio } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { useSelector } from "react-redux";
import { postDataAPI } from "../../utils/fetchData";
import { useState, useEffect } from "react";
import { PaytmButton } from "../paytm-button/paytmButton";

const useStyles = makeStyles({
  addressDiv: {
    marginTop: 64,
    "@media (max-width:660px)": {
      marginTop: 128,
    },
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "@media (max-width: 825px)": {
      width: "85%",
      marginLeft: "50%",
      transform: "translateX(-50%)",
    },
  },
  textContainers: {
    display: "flex",
    width: "90vw",
    justifyContent: "space-evenly",
    marginTop: "2%",
    "@media (max-width: 570px)": {
      flexDirection: "column",
    },
  },
  input: {
    height: 30,
  },
  inputDiv: {
    height: 70,
    display: "flex",
    justifyContent: "space-evenly",
    flexDirection: "column",
    width: "40%",
    "@media (max-width: 768px)": {
      width: "45%",
    },
    "@media (max-width: 570px)": {
      width: "90%",
      marginLeft: "50%",
      transform: "translateX(-50%)",
    },
  },
  btn: {
    width: "100%",
    border: "2px solid #252525",
    fontSize: "1.5rem",
    backgroundColor: "white",
    borderRadius: "10px",

    // marginLeft: "50%",
    // transform: "translateX(-50%)",
    // marginTop: "3%",
    // width: "20%",
    // "@media (max-width: 825px)": {
    //   width: "30%",
    // },
    // "@media (max-width: 768px)": {
    //   width: "40%",
    // },
    // "@media (max-width: 380px)": {
    //   width: "50%",
    // },
  },
  paymentDiv: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    marginTop: "3%",
    // border: "1px solid grey",
    width: "100%",
    // marginLeft: "50%",
    // transform: "translateX(-50%)",
    // padding: "1%",
    marginBottom: "2%",
    borderRadius: "10px",
    "@media (max-width: 825px)": {
      width: "85%",
    },
    "@media (max-width: 768px)": {
      padding: "3%",
    },
    "@media (max-width: 768px)": {
      marginTop: "5%",
      padding: "5%",
    },
  },
  paymentForm: {
    marginTop: "3%",
    width: "100%",
  },
  heading: {
    "@media (max-width: 980px)": {
      fontSize: "2rem",
    },
    "@media (max-width: 768px)": {
      fontSize: "1.5rem",
    },
  },
  pricesummary: {
    padding: "10px",
    borderRadius: "10px",
    backgroundColor: "#A771FF",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  summary: {
    border: "2px solid #252525",
    padding: "1rem",
    borderRadius: "10px",
    width: "20vw",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  summaryPrice: {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "flex-start",
    flexDirection: "column",
    marginTop: "1rem",
    height: "15vh",
  },
  Detailss: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    width: "100%",
  },
  line: {
    borderBottom: "1px solid black",
    width: "100%",
  },
});

const PaymentPage = () => {
  const price = localStorage.getItem("totalAmount");
  const classes = useStyles();
  const [values, setValues] = useState([]);
  const [total, setTotal] = useState(0);
  const delivery = Number(localStorage.getItem("totalDelivery"));
  let address = {
    //user address
    city: "delhi",
    flat: "test flat",
    state: "delhi",
    pin: "201010",
  };
  let orderDetails = {
    delivery: address,
    mobile: "9582904144", //user.mobile
    amount: price, //cart.js total amount
  };
  const { auth } = useSelector((state) => state);
  function buildForm({ action, params }) {
    const form = document.createElement("form");
    form.setAttribute("method", "post");
    form.setAttribute("action", action);

    Object.keys(params).forEach((key) => {
      const input = document.createElement("input");
      input.setAttribute("type", "hidden");
      input.setAttribute("name", key);
      input.setAttribute("value", params[key]);
      form.appendChild(input);
    });

    return form;
  }
  function post(details) {
    // alert("hi");
    const form = buildForm(details);
    console.warn("form", form);
    document.body.appendChild(form);

    form.submit();
    form.remove();
  }
  const makepayment = async (auth) => {
    console.log("hi");
    console.log(auth);
    const res = await postDataAPI(`checkout`, orderDetails, auth.token);
    console.warn(res.data);
    var information = {
      action: "https://securegw-stage.paytm.in/theia/processTransaction",
      params: res.data,
    };
    post(information);
    console.log(information);
  };
  async function handleFormSubmit(e) {
    e.preventDefault();
  }
  useEffect(() => {
    setValues(auth.user.cart);
  }, [auth.user.cart]);
  useEffect(() => {
    var temp = 0;
    for (var i = 0; i < values.length; i++) {
      if (values[i].product) {
        temp += parseInt(values[i].product.price) * values[i].quantity;
      }
    }
    setTotal(temp);
  }, [values]);
  return (
    <div>
      <div className={classes.paymentDiv}>
        <div className={classes.summary}>
          <div className={classes.pricesummary}>Price Summary ({values.length} items)</div>
          <div className={classes.summaryPrice}>
            <div className={classes.Detailss}>
              <div className={classes.heading} variant="h4" color="primary">
                Total Cart
              </div>
              <div className={classes.headingprice}>{orderDetails.amount}</div>
            </div>
            <div className={classes.Detailss}>
              <div>Delivery Fee</div>
              <div>{delivery}</div>
            </div>
            <div className={classes.line}></div>
            <div>Total: {+orderDetails.amount + +delivery}</div>
          </div>
        </div>
        <form className={classes.paymentForm} onSubmit={handleFormSubmit}>
          <PaytmButton orderDetails={orderDetails}></PaytmButton>
          {/* <button
            variant="contained"
            color="primary"
            className={classes.btn}
            onClick={() => (window.location.href = `/success`)}
          >
            Pay on delivery
          </button> */}
        </form>
      </div>
    </div>
  );
};

export default PaymentPage;

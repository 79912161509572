import React, { useState, useEffect } from "react";
import { patchDataAPI } from "../../utils/fetchData";
import { useSelector, useDispatch } from "react-redux";
import { addAddress } from "../../redux/actions/authAction";
import { CallMissedSharp } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core";
import Swal from "sweetalert2";

const useStyles = makeStyles((theme) => ({
  main: {
    width: "75%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "1.2rem",
    flexDirection: "column",
    "@media (max-width:550px)": {
      width: "100%",
    },
  },
  card: {
    width: "100%",
    height: "fit-content",
    backgroundColor: "white",
    borderRadius: "1rem",
    display: "flex",
    flexDirection: "column",
    gap: "50px",
    "@media (max-width:550px)": {
      alignItems: "center",
    },
    // boxShadow: "1px 1px 10px rgba(0, 0, 0, 0.25);",
  },
  CustomerInfoMain: {
    border: "1px solid black",
    padding: "20px",
    "@media (max-width:550px)": {
      width: "100%",
    },
  },
  CustomerInfoHead: {
    fontSize: "0.78rem",
    padding: "10px",
    fontFamily: "poppins, sans-serif",
  },
  CustomerInfoData: {
    paddingLeft: "10px",

    fontFamily: "poppins, sans-serif",
    fontSize: "0.7rem",
    width: "100%",
    borderBottom: "1px solid #cacaca",
  },
  CustomerInfoInput: {
    borderRadius: "5px",
    width: "100%",
    border: "1px solid black",
  },
  CustomerInfoTxt: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    fontFamily: '"Poppins", sans-serif',
    fontWeight: "600",
  },
  upper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "10px",
    alignItems: "center",
  },
  circle: {
    height: "1rem",
    width: "1rem",
    backgroundColor: "#A771FF",
    borderRadius: "50%",
  },

  cross: {
    backgroundColor: "white",
    border: "1px solid black",
  },
  inputDivMain: {
    display: "flex",
  },
  inputDivTxt: {
    fontFamily: "poppins, sans-serif",
  },
  impStar: {
    color: "red",
    fontFamily: "poppins, sans-serif",
  },
  address: {
    paddingLeft: "10px",
    textTransform: "capitalize",
  },
  Detailss: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    width: "100%",
    padding: "10px",
    border: "1px solid black",
  },
  inputBox: {
    border: "1px solid #252525",
    borderRadius: "4px",
    width: "80%",
    textTransform: "capitalize",
    paddingLeft: "5px",
    "&::placeholder": {
      paddingLeft: "5px",
      fontSize: "0.9rem",
      color: "#9b9b9b",
    },
  },
  btn: {
    backgroundColor: "black",
    border: "1px solid black",
    width: "fit-content",
    padding: "10px",
    minWidth: "100px",
    marginBottom: "20px",
    fontSize: "0.9rem",
    color: "white",
    fontFamily: "poppins, sans-serif",
    fontWeight: "500",
    letterSpacing: "1px",
    "&:hover": {
      // fontWeight: "600",
      transition: "all .8s ease-out",
      boxShadow: "-5.5px 10px #222222",
    },
  },
  cityStateDiv: {
    padding: "0px",
    display: "flex",
    flexDirection: "row",
    // gap: "5%",
  },
  cityStateMainDiv: {
    display: "flex",
    paddingLeft: "10px",
    paddingRight: "10px",
    gap: "10px",

    width: "100%",
    "@media (max-width:550px)": {
      flexDirection: "column",
      gap: "10px",
    },
  },
}));

const Address = () => {
  const { auth } = useSelector((state) => state);
  const [userData, setUserData] = useState([]);
  const classes = useStyles();
  const dispatch = useDispatch();
  const [edit, setEdit] = useState(true);
  const [noAdd, setNoAdd] = useState(true);
  const [value, setValue] = useState({
    address: userData.address,
    city: userData.city,
    state: userData.state,
    mobile: userData.mobile,
    pincode: userData.pincode,
  });

  const [ADD, SETADD] = useState();
  const [CIT, SETCIT] = useState();
  const [PIN, SETPIN] = useState();
  const [STAT, SETSTAT] = useState();
  const [MOB, SETMOB] = useState();

  useEffect(() => {
    setUserData(auth.user);
    try {
      setValue({
        ...value,
        fullname: auth.user.fullname,
        username: auth.user.username,
        mobile: auth.user.mobile,
        gender: auth.user.gender,
        address: auth.user.address,
        bio: auth.user.bio,
        pincode: auth.user.pincode,
        city: auth.user.city,
        state: auth.user.state,
      });
    } catch (error) {
      console.log(error);
    }
  }, [auth.user]);
  async function handleFormSubmit(e) {
    e.preventDefault();
    dispatch(addAddress({ value, token: auth.token }));
  }

  // const totalcart = userData.cart.length;

  const regesx =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

  const editButton = (e) => {
    setEdit(false);
  };

  const submitButton = (e) => {
    e.preventDefault();
    if (value.pincode.length !== 6) {
      Swal.fire({
        icon: "error",
        title: "pincode should be 6",
        text: "Please try again :(",
      });
    } else if (!value.address) {
      Swal.fire({
        icon: "error",
        title: "please enter valid address",
        text: "Please try again :(",
      });
    } else if (
      !value.mobile ||
      !(value.mobile % value.mobile === 0) ||
      value.mobile.length !== 10
    ) {
      Swal.fire({
        icon: "error",
        title: "please enter valid phone number",
        text: "Please try again :(",
      });
    } else if (!value.city) {
      Swal.fire({
        icon: "error",
        title: "please enter valid city",
        text: "Please try again :(",
      });
    } else if (!value.state) {
      Swal.fire({
        icon: "error",
        title: "please enter valid state",
        text: "Please try again :(",
      });
    } else if (!value.pincode || !(value.pincode % value.pincode === 0)) {
      Swal.fire({
        icon: "error",
        title: "please enter valid pincode",
        text: "Please try again :(",
      });
    } else {
      dispatch(addAddress({ value, token: auth.token }));
      setEdit(true);
      window.location.reload();
    }
  };

  const handleAddress = (e) => {
    setValue({ ...value, address: e.target.value });
    SETADD(e.target.value);
  };

  const handleCity = (e) => {
    setValue({ ...value, city: e.target.value });
    SETCIT(e.target.value);
  };
  const handlePincode = (e) => {
    setValue({ ...value, pincode: e.target.value });
    SETPIN(e.target.value);
  };
  const handleMobile = (e) => {
    setValue({ ...value, mobile: e.target.value });
    SETMOB(e.target.value);
  };
  const handleState = (e) => {
    setValue({ ...value, state: e.target.value });
    SETSTAT(e.target.value);
  };

  const handleDelete = (e) => {
    setValue({ ...value, state: "", city: "", address: "", mobile: "", pincode: "" });
    SETCIT();
    SETADD();
    SETPIN();
    SETSTAT();
    SETMOB();
    setEdit(false);
  };
  return (
    <div>
      <div>
        {userData.address &&
        userData.pincode &&
        userData.mobile &&
        userData.city &&
        userData.state &&
        edit ? (
          <form className={classes.main} onSubmit={handleFormSubmit}>
            {userData && (
              <div style={{ width: "100%" }}>
                <div className={classes.card}>
                  <div className={classes.CustomerInfoMain}>
                    <div className={classes.CustomerInfoTxt}>Customer Info</div>
                    <div>
                      <div className={classes.CustomerInfoHead}>Email</div>
                      <div className={classes.CustomerInfoData}>{userData.email}</div>
                    </div>
                    <div>
                      <div className={classes.CustomerInfoHead}>Full Name</div>
                      <div
                        className={classes.CustomerInfoData}
                        style={{ textTransform: "uppercase" }}
                      >
                        {userData.fullname}
                      </div>
                    </div>
                  </div>

                  {/* <button className={classes.cross} onClick={handleDelete}>
                                  delete address
                                </button> */}

                  <div className={classes.Detailss}>
                    <div
                      style={{
                        fontFamily: "poppins, sans-serif",
                        paddingLeft: "10px",
                        paddingBottom: "5px",
                        fontWeight: "500",
                        letterSpacing: "1px",
                      }}
                    >
                      Shipping Address
                    </div>
                    <div
                      style={{
                        padding: "10px",
                        display: "flex",
                        flexDirection: "column",
                        gap: "5px",
                      }}
                    >
                      <div className={classes.inputDivMain}>
                        <div className={classes.inputDivTxt}>Phone no.</div>{" "}
                        <div className={classes.impStar}>*</div>
                      </div>
                      <div
                        style={{
                          fontFamily: "poppins, sans-serif",
                          borderBottom: "1px solid black",
                        }}
                      >
                        {" "}
                        {MOB ? MOB : userData.mobile}
                      </div>
                    </div>
                    <div
                      style={{
                        padding: "10px",
                        display: "flex",
                        flexDirection: "column",
                        gap: "5px",
                        fontFamily: "poppins, sans-serif",
                      }}
                    >
                      <div className={classes.inputDivMain}>
                        {" "}
                        <div className={classes.inputDivTxt}>Address</div>{" "}
                        <div className={classes.impStar}>*</div>
                      </div>

                      <div style={{ textTransform: "capitalize", borderBottom: "1px solid black" }}>
                        {ADD ? ADD : userData.address}
                      </div>
                    </div>
                    <div style={{ padding: "10px", display: "flex", flexDirection: "column" }}>
                      <div className={classes.inputDivMain}>
                        <div className={classes.inputDivTxt}>State</div>{" "}
                        <div className={classes.impStar}>*</div>
                      </div>
                      <div
                        style={{
                          textTransform: "capitalize",
                          fontFamily: "poppins, sans-serif",
                          borderBottom: "1px solid black",
                        }}
                      >
                        {STAT ? STAT : userData.state}
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",

                        paddingLeft: "0px",
                        paddingRight: "10px",
                        gap: "10%",
                        justifyContent: "space-around",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          padding: "10px",
                          display: "flex",
                          flexDirection: "column",
                          width: "30%",
                        }}
                      >
                        <div className={classes.inputDivMain}>
                          {" "}
                          <div className={classes.inputDivTxt}>City</div>
                          <div className={classes.impStar}>*</div>
                        </div>
                        <div
                          style={{
                            textTransform: "capitalize",
                            fontFamily: "poppins, sans-serif",
                            borderBottom: "1px solid black",
                            textAlign: "center",
                          }}
                        >
                          {CIT ? CIT : userData.city}
                        </div>
                      </div>
                      <div
                        style={{
                          padding: "10px",
                          display: "flex",
                          flexDirection: "column",
                          width: "30%",
                        }}
                      >
                        <div className={classes.inputDivMain}>
                          <div className={classes.inputDivTxt}>Pincode</div>
                          <div className={classes.impStar}>*</div>
                        </div>
                        <div
                          style={{
                            fontFamily: "poppins, sans-serif",
                            borderBottom: "1px solid black",
                            textAlign: "center",
                          }}
                        >
                          {PIN ? PIN : userData.pincode}
                        </div>
                      </div>
                    </div>
                  </div>
                  <button
                    onClick={editButton}
                    className={classes.btn}
                    style={{ fontFamily: "poppins, sans-serif" }}
                  >
                    Edit
                  </button>
                </div>
              </div>
            )}{" "}
          </form>
        ) : (
          <form className={classes.main} onSubmit={handleFormSubmit}>
            {userData && (
              <div className={classes.card}>
                <div className={classes.CustomerInfoMain}>
                  <div className={classes.CustomerInfoTxt}>Customer Info</div>
                  <div>
                    <div className={classes.CustomerInfoHead}>Email</div>
                    <div className={classes.CustomerInfoData}>{userData.email}</div>
                  </div>
                  <div>
                    <div className={classes.CustomerInfoHead}>Full Name</div>
                    <div
                      className={classes.CustomerInfoData}
                      style={{ textTransform: "uppercase" }}
                    >
                      {userData.fullname}
                    </div>
                  </div>
                </div>
                <div className={classes.Detailss}>
                  <div
                    className={classes.address}
                    style={{
                      padding: "10px",
                      display: "flex",
                      flexDirection: "row",
                      gap: "1%",
                    }}
                  >
                    <div className={classes.inputDivMain}>
                      <div className={classes.inputDivTxt}>Address</div>{" "}
                      <div className={classes.impStar}>*</div>
                    </div>
                    <input
                      className={classes.inputBox}
                      placeholder={ADD ? ADD : userData.address}
                      onChange={handleAddress}
                      required="true"
                    ></input>
                  </div>
                  <div className={classes.cityStateMainDiv}>
                    <div className={classes.cityStateDiv}>
                      <div className={classes.inputDivMain}>
                        <div className={classes.inputDivTxt}>City</div>
                        <div className={classes.impStar}>*</div>
                      </div>
                      <input
                        placeholder={CIT ? CIT : userData.city}
                        className={classes.inputBox}
                        onChange={handleCity}
                        required="true"
                      ></input>
                    </div>
                    <div
                      style={{
                        padding: "0px",
                        display: "flex",
                        flexDirection: "row",
                        gap: "2%",
                      }}
                    >
                      <div className={classes.inputDivMain}>
                        <div className={classes.inputDivTxt}>State</div>{" "}
                        <div className={classes.impStar}>*</div>
                      </div>
                      <input
                        placeholder={STAT ? STAT : userData.state}
                        className={classes.inputBox}
                        onChange={handleState}
                        required="true"
                      ></input>
                    </div>
                  </div>
                  <div
                    style={{
                      padding: "10px",
                      display: "flex",
                      flexDirection: "row",
                      gap: "1%",
                    }}
                  >
                    <div className={classes.inputDivMain}>
                      <div className={classes.inputDivTxt}>Pincode</div>{" "}
                      <div className={classes.impStar}>*</div>
                    </div>
                    <input
                      placeholder={PIN ? PIN : userData.pincode}
                      className={classes.inputBox}
                      onChange={handlePincode}
                      required="true"
                    ></input>
                  </div>
                  <div
                    style={{
                      padding: "10px",
                      display: "flex",
                      flexDirection: "row",
                      gap: "1%",
                    }}
                  >
                    <div className={classes.inputDivMain}>
                      <div className={classes.inputDivTxt}>Phone</div>{" "}
                      <div className={classes.impStar}>*</div>
                    </div>
                    <input
                      placeholder={MOB ? MOB : userData.mobile}
                      className={classes.inputBox}
                      onChange={handleMobile}
                      required="true"
                    ></input>
                  </div>{" "}
                </div>
                <button onClick={submitButton} type="submit" className={classes.btn}>
                  Submit
                </button>
              </div>
            )}{" "}
          </form>
        )}
      </div>
    </div>
    // <div>
    //   {console.log(userData)}
    //   <div>
    //     {(userData.address &&
    //       userData.pincode &&
    //       userData.mobile &&
    //       userData.city &&
    //       userData.state) ||
    //     edit ? (
    //       <div>
    //         {edit ? (
    //           <form className={classes.main} onSubmit={handleFormSubmit}>
    //             {userData && (
    //               <div style={{ width: "100%" }}>
    //                 <div className={classes.card}>
    //                   <div className={classes.CustomerInfoMain}>
    //                     <div className={classes.CustomerInfoTxt}>Customer Info</div>
    //                     <div>
    //                       <div className={classes.CustomerInfoHead}>Email</div>
    //                       <div className={classes.CustomerInfoData}>{userData.email}</div>
    //                     </div>
    //                     <div>
    //                       <div className={classes.CustomerInfoHead}>Full Name</div>
    //                       <div
    //                         className={classes.CustomerInfoData}
    //                         style={{ textTransform: "uppercase" }}
    //                       >
    //                         {userData.fullname}
    //                       </div>
    //                     </div>
    //                   </div>

    //                   {/* <button className={classes.cross} onClick={handleDelete}>
    //                       delete address
    //                     </button> */}

    //                   <div className={classes.Detailss}>
    //                     <div
    //                       style={{
    //                         fontFamily: "poppins, sans-serif",
    //                         paddingLeft: "10px",
    //                         paddingBottom: "5px",
    //                         fontWeight: "500",
    //                         letterSpacing: "1px",
    //                       }}
    //                     >
    //                       Shipping Address
    //                     </div>
    //                     <div
    //                       style={{
    //                         padding: "10px",
    //                         display: "flex",
    //                         flexDirection: "column",
    //                         gap: "5px",
    //                       }}
    //                     >
    //                       <div style={{ fontFamily: "poppins, sans-serif" }}>
    //                         Phone no.{" "}
    //                         <sudivtclassName={classes.impStar}>*</div>
    //                       </div>
    //                       <div
    //                         style={{
    //                           fontFamily: "poppins, sans-serif",
    //                           borderBottom: "1px solid black",
    //                         }}
    //                       >
    //                         {" "}
    //                         {MOB ? MOB : userData.mobile}
    //                       </div>
    //                     </div>
    //                     <div
    //                       style={{
    //                         padding: "10px",
    //                         display: "flex",
    //                         flexDirection: "column",
    //                         gap: "5px",
    //                         fontFamily: "poppins, sans-serif",
    //                       }}
    //                     >
    //                       <div>
    //                         {" "}
    //                         Address{" "}
    //                         <div className={classes.impStar}>*</div>
    //                       </div>

    //                       <div
    //                         style={{ textTransform: "capitalize", borderBottom: "1px solid black" }}
    //                       >
    //                         {ADD ? ADD : userData.address}
    //                       </div>
    //                     </div>
    //                     <div style={{ padding: "10px", display: "flex", flexDirection: "column" }}>
    //                       <div style={{ fontFamily: "poppins, sans-serif" }}>
    //                         State{" "}
    //                         <div className={classes.impStar}>*</div>
    //                       </div>
    //                       <div
    //                         style={{
    //                           textTransform: "capitalize",
    //                           fontFamily: "poppins, sans-serif",
    //                           borderBottom: "1px solid black",
    //                         }}
    //                       >
    //                         {STAT ? STAT : userData.state}
    //                       </div>
    //                     </div>
    //                     <div
    //                       style={{
    //                         display: "flex",

    //                         paddingLeft: "0px",
    //                         paddingRight: "10px",
    //                         gap: "10%",
    //                         justifyContent: "space-around",
    //                         alignItems: "center",
    //                       }}
    //                     >
    //                       <div
    //                         style={{
    //                           padding: "10px",
    //                           display: "flex",
    //                           flexDirection: "column",
    //                           width: "30%",
    //                         }}
    //                       >
    //                         <div style={{ fontFamily: "poppins, sans-serif", textAlign: "center" }}>
    //                           {" "}
    //                           City
    //                           <div className={classes.impStar}>*</div>
    //                         </div>
    //                         <div
    //                           style={{
    //                             textTransform: "capitalize",
    //                             fontFamily: "poppins, sans-serif",
    //                             borderBottom: "1px solid black",
    //                             textAlign: "center",
    //                           }}
    //                         >
    //                           {CIT ? CIT : userData.city}
    //                         </div>
    //                       </div>
    //                       <div
    //                         style={{
    //                           padding: "10px",
    //                           display: "flex",
    //                           flexDirection: "column",
    //                           width: "30%",
    //                         }}
    //                       >
    //                         <div style={{ fontFamily: "poppins, sans-serif", textAlign: "center" }}>
    //                           Pincode
    //                           <div className={classes.impStar}>*</div>
    //                         </div>
    //                         <div
    //                           style={{
    //                             fontFamily: "poppins, sans-serif",
    //                             borderBottom: "1px solid black",
    //                             textAlign: "center",
    //                           }}
    //                         >
    //                           {PIN ? PIN : userData.pincode}
    //                         </div>
    //                       </div>
    //                     </div>
    //                   </div>
    //                   <button
    //                     onClick={editButton}
    //                     className={classes.btn}
    //                     style={{ fontFamily: "poppins, sans-serif" }}
    //                   >
    //                     Edit Button
    //                   </button>
    //                 </div>
    //               </div>
    //             )}{" "}
    //           </form>
    //         ) : (
    //           <form className={classes.main} onSubmit={handleFormSubmit}>
    //             {userData && (
    //               <div className={classes.card}>
    //                 <div className={classes.CustomerInfoMain}>
    //                   <div className={classes.CustomerInfoTxt}>Customer Info</div>
    //                   <div>
    //                     <div className={classes.CustomerInfoHead}>Email</div>
    //                     <div className={classes.CustomerInfoData}>{userData.email}</div>
    //                   </div>
    //                   <div>
    //                     <div className={classes.CustomerInfoHead}>Phone Number</div>
    //                     <div
    //                       className={classes.CustomerInfoData}
    //                       style={{ textTransform: "uppercase" }}
    //                     >
    //                       {userData.fullname}
    //                     </div>
    //                   </div>
    //                 </div>
    //                 <div className={classes.Detailss}>
    //                   <div
    //                     className={classes.address}
    //                     style={{
    //                       padding: "10px",
    //                       display: "flex",
    //                       flexDirection: "row",
    //                       gap: "1%",
    //                     }}
    //                   >
    //                     <div>
    //                       Address{" "}
    //                       <div className={classes.impStar}>*</div>
    //                     </div>
    //                     <input
    //                       className={classes.inputBox}
    //                       placeholder={ADD ? ADD : userData.address}
    //                       onChange={handleAddress}
    //                       required="true"
    //                     ></input>
    //                   </div>
    //                   <div
    //                     style={{
    //                       display: "flex",

    //                       paddingLeft: "10px",
    //                       paddingRight: "10px",
    //                       gap: "4%",
    //                       width: "100%",
    //                     }}
    //                   >
    //                     <div
    //                       style={{
    //                         padding: "0px",
    //                         display: "flex",
    //                         flexDirection: "row",
    //                         gap: "2%",
    //                       }}
    //                     >
    //                       <div>
    //                         {" "}
    //                         City{" "}
    //                         <div className={classes.impStar}>*</div>
    //                       </div>
    //                       <input
    //                         placeholder={CIT ? CIT : userData.city}
    //                         className={classes.inputBox}
    //                         onChange={handleCity}
    //                         required="true"
    //                       ></input>
    //                     </div>
    //                     <div
    //                       style={{
    //                         padding: "0px",
    //                         display: "flex",
    //                         flexDirection: "row",
    //                         gap: "2%",
    //                       }}
    //                     >
    //                       <div>
    //                         State{" "}
    //                         <div className={classes.impStar}>*</div>
    //                       </div>
    //                       <input
    //                         placeholder={STAT ? STAT : userData.state}
    //                         className={classes.inputBox}
    //                         onChange={handleState}
    //                         required="true"
    //                       ></input>
    //                     </div>
    //                   </div>
    //                   <div
    //                     style={{
    //                       padding: "10px",
    //                       display: "flex",
    //                       flexDirection: "row",
    //                       gap: "1%",
    //                     }}
    //                   >
    //                     <div>
    //                       Pincode{" "}
    //                       <div className={classes.impStar}>*</div>
    //                     </div>
    //                     <input
    //                       placeholder={PIN ? PIN : userData.pincode}
    //                       className={classes.inputBox}
    //                       onChange={handlePincode}
    //                       required="true"
    //                     ></input>
    //                   </div>
    //                   <div
    //                     style={{
    //                       padding: "10px",
    //                       display: "flex",
    //                       flexDirection: "row",
    //                       gap: "1%",
    //                     }}
    //                   >
    //                     <div>
    //                       Phone <div className={classes.impStar}>*</div>
    //                     </div>
    //                     <input
    //                       placeholder={MOB ? MOB : userData.mobile}
    //                       className={classes.inputBox}
    //                       onChange={handleMobile}
    //                       required="true"
    //                     ></input>
    //                   </div>{" "}
    //                 </div>
    //                 <button onClick={submitButton} type="submit" className={classes.btn}>
    //                   Submit Button
    //                 </button>
    //               </div>
    //             )}{" "}
    //           </form>
    //         )}
    //       </div>
    //     ) : (
    //       <div>
    //         {" "}
    //         {noAdd ? (
    //           <div
    //             style={{
    //               display: "flex",
    //               flexDirection: "column",
    //               marginTop: "4%",
    //               width: "80%",
    //               alignItems: "center",
    //               gap: "20px",
    //             }}
    //           >
    //             <div style={{ fontSize: "1.3rem", fontFamily: "poppins, sens-serif" }}>
    //               You don't have any address please add one
    //             </div>
    //             <button
    //               className={classes.btn}
    //               style={{ width: "30%" }}
    //               onClick={(e) => {
    //                 setNoAdd(false);
    //               }}
    //             >
    //               Add Your Address
    //             </button>
    //           </div>
    //         ) : (
    //           <div>
    //             {edit ? (
    //               <form className={classes.main} onSubmit={handleFormSubmit}>
    //                 {userData && (
    //                   <div className={classes.card}>
    //                     <div className={classes.CustomerInfoMain}>
    //                       <div className={classes.CustomerInfoTxt}>Customer Info</div>
    //                       <div>
    //                         <div className={classes.CustomerInfoHead}>Email</div>
    //                         <div className={classes.CustomerInfoData}>{userData.email}</div>
    //                       </div>
    //                       <div>
    //                         <div className={classes.CustomerInfoHead}>Phone Number</div>
    //                         <div
    //                           className={classes.CustomerInfoData}
    //                           style={{ textTransform: "uppercase" }}
    //                         >
    //                           {userData.fullname}
    //                         </div>
    //                       </div>
    //                     </div>
    //                     <div className={classes.Detailss}>
    //                       <div
    //                         className={classes.address}
    //                         style={{
    //                           padding: "10px",
    //                           display: "flex",
    //                           flexDirection: "row",
    //                           gap: "1%",
    //                         }}
    //                       >
    //                         <div>
    //                           Address{" "}
    //                           <div className={classes.impStar}>*</div>
    //                         </div>
    //                         <input
    //                           className={classes.inputBox}
    //                           placeholder={ADD ? ADD : userData.address}
    //                           onChange={handleAddress}
    //                           required="true"
    //                         ></input>
    //                       </div>
    //                       <div
    //                         style={{
    //                           display: "flex",

    //                           paddingLeft: "10px",
    //                           paddingRight: "10px",
    //                           gap: "4%",
    //                           width: "100%",
    //                         }}
    //                       >
    //                         <div
    //                           style={{
    //                             padding: "0px",
    //                             display: "flex",
    //                             flexDirection: "row",
    //                             gap: "2%",
    //                           }}
    //                         >
    //                           <div>
    //                             {" "}
    //                             City{" "}
    //                             <div className={classes.impStar}>*</div>
    //                           </div>
    //                           <input
    //                             placeholder={CIT ? CIT : userData.city}
    //                             className={classes.inputBox}
    //                             onChange={handleCity}
    //                             required="true"
    //                           ></input>
    //                         </div>
    //                         <div
    //                           style={{
    //                             padding: "0px",
    //                             display: "flex",
    //                             flexDirection: "row",
    //                             gap: "2%",
    //                           }}
    //                         >
    //                           <div>
    //                             State{" "}
    //                             <div className={classes.impStar}>*</div>
    //                           </div>
    //                           <input
    //                             placeholder={STAT ? STAT : userData.state}
    //                             className={classes.inputBox}
    //                             onChange={handleState}
    //                             required="true"
    //                           ></input>
    //                         </div>
    //                       </div>
    //                       <div
    //                         style={{
    //                           padding: "10px",
    //                           display: "flex",
    //                           flexDirection: "row",
    //                           gap: "1%",
    //                         }}
    //                       >
    //                         <div>
    //                           Pincode{" "}
    //                           <div className={classes.impStar}>*</div>
    //                         </div>
    //                         <input
    //                           placeholder={PIN ? PIN : userData.pincode}
    //                           className={classes.inputBox}
    //                           onChange={handlePincode}
    //                           required="true"
    //                         ></input>
    //                       </div>
    //                       <div
    //                         style={{
    //                           padding: "10px",
    //                           display: "flex",
    //                           flexDirection: "row",
    //                           gap: "1%",
    //                         }}
    //                       >
    //                         <div>
    //                           Phone{" "}
    //                           <div className={classes.impStar}>*</div>
    //                         </div>
    //                         <input
    //                           placeholder={MOB ? MOB : userData.mobile}
    //                           className={classes.inputBox}
    //                           onChange={handleMobile}
    //                           required="true"
    //                         ></input>
    //                       </div>{" "}
    //                     </div>
    //                     <button onClick={submitButton} type="submit" className={classes.btn}>
    //                       Submit Button
    //                     </button>
    //                   </div>
    //                 )}{" "}
    //               </form>
    //             ) : (
    //               <form className={classes.main} onSubmit={handleFormSubmit}>
    //                 {userData && (
    //                   <div className={classes.card}>
    //                     <div className={classes.upper}>
    //                       <div
    //                         style={{
    //                           display: "flex",
    //                           flexDirection: "row",
    //                           gap: "10px",
    //                           alignItems: "center",
    //                         }}
    //                       >
    //                         <div className={classes.circle}></div>
    //                         <div>{userData.fullname}</div>
    //                       </div>
    //                     </div>
    //                     <div className={classes.Detailss}>
    //                       <div
    //                         className={classes.address}
    //                         style={{
    //                           padding: "10px",
    //                           display: "flex",
    //                           flexDirection: "row",
    //                           gap: "1%",
    //                         }}
    //                       >
    //                         <div>Address</div>
    //                         <input
    //                           className={classes.inputBox}
    //                           placeholder={ADD ? ADD : userData.address}
    //                           onChange={handleAddress}
    //                           required="true"
    //                         ></input>
    //                       </div>
    //                       <div
    //                         style={{
    //                           display: "flex",

    //                           paddingLeft: "10px",
    //                           paddingRight: "10px",
    //                           gap: "4%",
    //                           width: "100%",
    //                         }}
    //                       >
    //                         <div
    //                           style={{
    //                             padding: "0px",
    //                             display: "flex",
    //                             flexDirection: "row",
    //                             gap: "2%",
    //                           }}
    //                         >
    //                           <div> City</div>
    //                           <input
    //                             placeholder={CIT ? CIT : userData.city}
    //                             className={classes.inputBox}
    //                             onChange={handleCity}
    //                             required="true"
    //                           ></input>
    //                         </div>
    //                         <div
    //                           style={{
    //                             padding: "0px",
    //                             display: "flex",
    //                             flexDirection: "row",
    //                             gap: "2%",
    //                           }}
    //                         >
    //                           <div>State</div>
    //                           <input
    //                             placeholder={STAT ? STAT : userData.state}
    //                             className={classes.inputBox}
    //                             onChange={handleState}
    //                             required="true"
    //                           ></input>
    //                         </div>
    //                       </div>
    //                       <div
    //                         style={{
    //                           padding: "10px",
    //                           display: "flex",
    //                           flexDirection: "row",
    //                           gap: "1%",
    //                         }}
    //                       >
    //                         <div>Pincode</div>
    //                         <input
    //                           placeholder={PIN ? PIN : userData.pincode}
    //                           className={classes.inputBox}
    //                           onChange={handlePincode}
    //                           required="true"
    //                         ></input>
    //                       </div>
    //                       <div
    //                         style={{
    //                           padding: "10px",
    //                           display: "flex",
    //                           flexDirection: "row",
    //                           gap: "1%",
    //                         }}
    //                       >
    //                         <div>Phone </div>
    //                         <input
    //                           placeholder={MOB ? MOB : userData.mobile}
    //                           className={classes.inputBox}
    //                           onChange={handleMobile}
    //                           required="true"
    //                         ></input>
    //                       </div>{" "}
    //                       <button onClick={submitButton} type="submit" className={classes.btn}>
    //                         Submit Button
    //                       </button>
    //                     </div>
    //                   </div>
    //                 )}{" "}
    //               </form>
    //             )}
    //           </div>
    //         )}
    //       </div>
    //     )}
    //   </div>
    // </div>
  );
};

export default Address;

import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { Button, Divider, IconButton, TextField } from "@material-ui/core";
import { Typography, makeStyles, useTheme, useMediaQuery } from "@material-ui/core";
import { Navbar } from "../components/Navbar";
import DeleteIcon from "@material-ui/icons/Delete";
import { updateQuantity, deleteQuantity } from "../redux/actions/profileAction";
import WishlistComponent from "../components/CartComonent/wishlist";
import { useHistory } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { Helmet } from "react-helmet";
import "react-loading-skeleton/dist/skeleton.css";
import ReactGA from "react-ga";

const useStyles = makeStyles({
  mainContainerU: {
    display: "flex",
    position: "absolute",
    width: "100%",
  },
  mainContainer: {
    // position: "absolute",
    top: 0,
    left: 0,
    marginTop: 64,
    "@media (max-width:660px)": {
      marginTop: 40,
    },
    width: "100%",
    height: "100%",

    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    // justifyContent: "center",
    // backgroundColor: "#F3F1F5",

    overflow: "visible",
    "@media (max-width:800px)": {
      flexDirection: "column-reverse",
      overflow: "scroll",
      justifyContent: "flex-start",
      alignItems: "center",
    },
    fontFamily: " 'Poppins', sans-serif",
  },
  productsDiv: {
    "@media (max-width:800px)": {
      marginBottom: "65px",
    },
  },
  left: {
    // position: "relative",
    // top: 0,
    // marginTop: 50,
    // left: 0,
    // alignItems: "center",
    // width: "55%",
    // height: "100%",
    // display: "flex",
    // flexDirection: "column",
    // "@media (max-width:600px)": {
    //   width: "90%",
    // },
  },
  // marginTop: {
  //   marginTop: "2%",
  // },
  // productsDiv: {
  //   position: "relative",
  //   top: 0,
  //   left: 0,
  //   width: "100%",
  //   height: 350,
  //   overflow: "scroll",
  // },
  // productDiv: {
  //   position: "relative",
  //   marginTop: "3%",
  //   top: 0,
  //   left: 0,
  //   backgroundColor: "white",
  //   width: "100%",
  //   height: "fit-content",

  //   padding: "2% 0 2% 0",
  //   display: "flex",
  //   alignItems: "center",
  //   justifyContent: "space-around",
  // },
  // imageContainer: {
  //   position: "relative",
  //   top: 0,
  //   left: 0,
  //   width: "25%",
  //   "&:after": {
  //     content: '" "',
  //     display: "block",
  //     width: "100%",
  //     paddingTop: "100%",
  //   },
  // },
  // image: {
  //   position: "absolute",
  //   top: 0,
  //   left: 0,
  //   width: "100%",
  // },
  // infosContainer: {
  //   position: "relative",
  //   top: 0,

  //   left: 0,
  //   width: "80%",
  //   height: "100%",
  //   display: "flex",
  //   flexDirection: "column",
  // },

  // nameContainer: {
  //   position: "relative",
  //   top: 0,
  //   left: 0,
  //   width: "100%",
  //   "& p": {
  //     fontSize: "1.5rem",
  //     "@media (max-width:850px)": {
  //       fontSize: "1rem",
  //     },
  //   },
  // },

  // detailsContainer: {
  //   position: "relative",
  //   top: 0,
  //   left: 0,
  //   width: "100%",
  //   height: "100%",
  //   display: "flex",
  //   flexDirection: "row",
  //   justifyContent: "space-around",
  //   "@media (max-width:850px)": {
  //     justifyContent: "space-between",
  //     flexDirection: "column",
  //   },
  // },

  // infoContainer: {
  //   position: "relative",
  //   top: 0,
  //   left: 0,
  //   width: "10%",
  //   display: "flex",
  //   flexDirection: "column",
  //   "@media (max-width:850px)": {
  //     justifyContent: "space-between",
  //     flexDirection: "row",
  //     width: "100%",
  //   },
  // },
  headingContainer: {
    "& p": {
      fontSize: "0.7rem",
      whiteSpace: "nowrap",
      margin: 0,
    },
  },
  valueContainer: {
    "& p": {
      fontSize: "1.1rem",
      whiteSpace: "nowrap",
      margin: 0,
    },
  },

  textField: {
    width: "100%",
  },
  right: {
    // position: "relative",
    marginTop: 200,
    left: 0,
    transform: "translateY(-50%)",
    alignItems: "center",
    width: "30%",
    height: "fit-content",
    backgroundColor: "white",
    padding: "2%",
    // border: "1px solid black",
    // borderRadius: 5,
    marginLeft: 30,
    "@media (max-width:800px)": {
      width: "90%",
      top: 0,
      transform: "translateY(0px)",
      marginTop: 30,
      marginLeft: "0",
    },
  },
  navbarRight: {
    "@media (max-width:400px)": {
      // fontWeight: "300",

      fontSize: ".8rem",
    },
  },
  btn: {
    marginTop: "6%",
    // position: "absolute",
    // width: "70%",
    display: "none",
    backgroundImage: "linear-gradient(to right, #29323c, #485563, #2b5876, #4e4376)",
    border: "none",
    padding: "5px",
    // borderRadius: "5px",
    width: "100%",
    color: "white",
    fontWeight: "600",
    position: "fixed",
    bottom: "0",
    height: "50px",
    // "@media (max-width:800)": {
    //   display: "inline",
    // },
    "@media (max-width:800px)": {
      // fontWeight: "300",

      display: "inline",
    },
  },
  btn1: {
    marginTop: "6%",
    // position: "absolute",
    // width: "70%",
    backgroundImage: "linear-gradient(to right, #29323c, #485563, #2b5876, #4e4376)",
    "@media (max-width:1180)": {
      fontSize: "3px",
    },
    // backgroundColor: "#8777DD",
    border: "none",
    padding: "5px",
    borderRadius: "5px",
    width: "90%",
    color: "white",
    fontWeight: "600",
    "@media (max-width:1160px)": {
      // fontWeight: "300",
      fontSize: ".85rem",
      transition: "all .4s ease-in-out",
    },
    "@media (max-width:800px)": {
      // fontWeight: "300",

      display: "none",
    },
    // backgroundImage: "linear-gradient(to right, white)",
    backgroundImage: "linear-gradient(to right, #29323c, #485563, #2b5876, #4e4376)",
    // MozTransition: "all .4s ease-in-out",
    // WebkitTransition: "all .4s ease-in-out",
    // transition: "all .4s ease-in-out",

    "&:hover": {
      transition: "all .2s ease-out",
      boxShadow: "1px 1px 5px #485563",
      // backgroundPosition: "100% 0",
      // MozTransition: "all 1s ease-in-out",
      // WebkitTransition: "all 1s ease-in-out",

      // backgroundColor: "white",
    },
  },
  rightTextDetailss: {
    marginTop: "6%",
    display: "flex",
    justifyContent: "center",

    // position: "absolute"
  },
  textValue: {
    "@media (max-width:1160px)": {
      // fontWeight: "300",
      fontSize: ".85rem",
      transition: "all .4s ease-in-out",
    },
  },
  line: {
    borderTop: "1px solid #dcdcdc",
  },

  ProductImage: {
    width: "80%",
    height: "auto",
    cursor: "pointer",
    maxWidth: "400px",
  },
  ProductContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    margin: "4% 2% 4% 2%",
  },
  DelButton: {
    backgroundColor: "white",
    border: "none",
    outline: "none",
    height: "fit-content",
    padding: "1%",
  },
  ProductName: {
    fontFamily: " 'Poppins', sans-serif",
    fontSize: "1.2rem",
    letterSpacing: "1.4px",
    fontWeight: "600",
    textTransform: "capitalize",
    cursor: "pointer",
    "@media (max-width:800px)": {
      fontSize: "1rem",
    },
  },
  cartDetailssText: {
    "@media (max-width:800px)": {
      fontSize: ".8rem",
    },
  },
  cartDetailssTextProductPrice: {
    color: "#F40000",
    fontFamily: " 'Poppins', sans-serif",
    fontWeight: "300",
    letterSpacing: "1px",
    "@media (max-width:800px)": {
      fontSize: ".83rem",
    },
  },
  myBag: {
    display: "flex",
    justifyContent: "flex-start",
    width: "100%",
    marginBottom: "4%",
    fontFamily: " 'Poppins', sans-serif",
    fontWeight: "600",
    textTransform: "capitalize",
    "@media (max-width:800px)": {
      fontSize: "1.2rem",
      justifyContent: "center",
    },
  },
  cartDetailssTextProductMrp: {
    textDecoration: "line-through",
    fontSize: "0.75rem",
    "@media (max-width:800px)": {
      fontSize: ".64rem",
    },
  },
  divider: {
    borderTop: "1px dashed black",
    width: "30px",
    // marginTop : "10px"
    "@media (max-width:700px)": {
      // fontWeight: "300",
      display: "none",
    },
  },
  ProductUser: {
    fontFamily: " 'Poppins', sans-serif",
    fontWeight: "400",
    letterSpacing: "1px",
    fontSize: "0.85rem",
    margin: "2% 0% 2% 0%",
    cursor: "pointer",
    "@media (max-width:800px)": {
      fontSize: "0.75rem",
    },
  },
  ProductQty: {
    width: "3rem",
    "@media (max-width:800px)": {
      width: "2rem",
      fontSize: "0.78rem",
    },
  },
  ProductDec: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "50%",
  },
  ProductTexts: {
    display: "flex",
    flexDirection: "column",
    gap: "0.9%",
    width: "100%",
  },
  rightMain: {
    border: "1px solid black",
    padding: "2%",
    display: "flex",
    flexDirection: "column",
  },
  rightPriceMain: {
    display: "flex",
    gap: "2%",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  mainSkeletonContainer: {
    display: "flex",
    width: "70vw",
    height: "100vh",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
  },
  navbarMain: {
    backgroundColor: "white",
    display: "flex",
    justifyContent: "space-evenly",
    // border: "1px solid black",
    alignItems: "center",
    height: "50px",

    position: "fixed",
    zIndex: "100",
    boxShadow: "10px -6px 12px -4px rgba(85,85,85,0.74)",
    width: "100%",
  },
  logo: {
    zIndex: 3,
    position: "relative",
    top: "50%",
    left: 0,
    // transform: "translateY(-50%)",
    marginLeft: 10,
    height: "auto",
    width: "100px",
    "@media (max-width:440px)": {
      width: 70,
    },
    "& img": {
      position: "relative",
      top: 0,
      left: 0,
      width: "100%",
      height: "auto",
    },
  },
  navbarTextMain: {
    "@media (max-width:700px)": {
      // fontWeight: "300",
      fontSize: "1.2rem",
    },
  },
  navbarSubText: {
    "@media (max-width:700px)": {
      // fontWeight: "300",
      display: "none",
    },
  },
});

const Cart = () => {
  const { auth } = useSelector((state) => state);
  const [values, setValues] = useState([]);
  const [filteredValue, setFilteredValue] = useState([]);
  const [total, setTotal] = useState(0);
  const [seller, setSeller] = useState(0);
  const [cartLoading, setCartLoading] = useState(true);
  const [discount, setDiscount] = useState(0);
  const dispatch = useDispatch();
  const theme = useTheme();
  const match = useMediaQuery(theme.breakpoints.down(850));
  const classes = useStyles();
  const history = useHistory();
  const delivery = 30;
  const newArr = [];
  useEffect(() => {
    // if (auth.token) {
    //   setValues(auth.user.cart);
    // }else{
    //   history.push("/login")
    // }
    const token = localStorage.getItem("firstLogin");
    if (token) {
      // history.push('/login');
      setCartLoading(true);
      if (auth.user) {
        setCartLoading(false);
        setValues(auth.user.cart);
      }
    } else {
      history.push("/login");
    }
  }, [auth]);
  const newPriceObj = {};
  const priceArray = [];
  useEffect(() => {
    if (auth.user) {
      console.log(values);
      for (let i = 0; i < values.length; i++) {
        // console.log(values[i].user);
        const newPriceObjSeller = {};
        // newArr.push(values[i].user);
        console.log(values);
        newPriceObj[`${values[i].user}`] = values[i].product.price;
        newPriceObjSeller[`${values[i].user}`] = values[i].product.price;
        priceArray.push(newPriceObjSeller);
        // priceArray.push((newPriceObjSeller[`${values[i].user}`] = values[i].product.price));

        // newPriceObj.values[i].user = values[i].price;
      }
      console.log(newPriceObj, "newPriceObj");
      console.log(newArr);
      console.log(priceArray, "priceArray");
      const counts = {};
      // console.log(newArr, "newArr");
      setFilteredValue(...newArr);
      // console.log(filteredValue, "filteredValue");
      newArr.forEach(function (x) {
        counts[x] = (counts[x] || 0) + 1;
      });
      console.log(counts);
      const objectLength = Object.keys(counts).length;
      setSeller(objectLength);
    }
  }, [values]);

  useEffect(() => {
    var temp = 0;
    var tempMrp = 0;
    for (var i = 0; i < values.length; i++) {
      if (values[i].product) {
        temp += parseInt(values[i].product.price) * values[i].quantity;
      }
    }
    setTotal(temp);
    for (var i = 0; i < values.length; i++) {
      if (values[i].product) {
        tempMrp = +parseInt(values[i].product.mrp) * values[i].quantity;
      }
    }
    setDiscount(tempMrp);
  }, [values]);
  async function handleChangeQuantity(id, user, quantity, size) {
    dispatch(updateQuantity({ data: auth.user.cart, user: user, id, quantity, auth, size }));
    var temp = 0;
    var tempMrp = 0;
    for (var i = 0; i < values.length; i++) {
      temp += parseInt(values[i].product.price) * values[i].quantity;
    }
    setTotal(temp);
    for (var i = 0; i < values.length; i++) {
      tempMrp += parseInt(values[i].product.mrp) * values[i].quantity;
    }
    setDiscount(tempMrp);
  }
  async function handleDelete(id, user, size, color) {
    dispatch(deleteQuantity({ data: auth.user.cart, user: user, id, auth, size, color: color }));
  }

  return (
    <div>
      <Helmet>
        <title>SARVH : CART</title>
      </Helmet>
      {/* <Navbar /> */}
      <div className={classes.navbarMain}>
        <a href="/" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <img
            src="https://aws-sarvh-product-images-production.s3.ap-south-1.amazonaws.com/assets/logo.png"
            alt=""
            className={classes.logo}
          />
        </a>
        <div
          style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "5px" }}
        >
          <div
            style={{
              fontFamily: "poppins, sans-serif",
              borderBottom: "2px solid green",
              color: "green",
              fontWeight: "500",
            }}
            className={classes.navbarTextMain}
          >
            Cart
          </div>

          <div className={classes.divider} style={{ fontFamily: "poppins, sans-serif" }}></div>
          <div style={{ fontFamily: "poppins, sans-serif" }} className={classes.navbarSubText}>
            Address
          </div>
          <div className={classes.divider} style={{ fontFamily: "poppins, sans-serif" }}></div>
          <div style={{ fontFamily: "poppins, sans-serif" }} className={classes.navbarSubText}>
            Checkout
          </div>
        </div>
        <div style={{ fontFamily: "poppins, sans-serif" }} className={classes.navbarRight}>
          {" "}
          <svg width="45" height="45" viewBox="0 0 64 64">
            <defs>
              <path id="a" d="M.06 64V.04H64V64z" />
            </defs>
            <g fill="none" fill-rule="evenodd">
              <mask id="b" fill="#fff"></mask>
              <path
                fill="#B85756"
                d="M64 32c0 17.673-14.327 32-32 32C14.327 64 0 49.673 0 32 0 14.327 14.327 0 32 0c17.673 0 32 14.327 32 32"
                mask="url(#b)"
              />
              <path
                fill="#974D4C"
                d="M32 56s-18-6.811-18-21.495V20.883S19.81 14 32 14v42M32 56V14c12.19 0 18 6.883 18 6.883v13.622C50 49.189 32 56 32 56"
              />
              <path fill="#F0CB83" d="M32 54s-18-6.811-18-21.495V18.883S19.81 12 32 12v42" />
              <path fill="#D5915B" d="M32 54s18-6.811 18-21.495V18.883S44.19 12 32 12v42" />
            </g>
          </svg>{" "}
          100% secure
        </div>
      </div>
      {cartLoading ? (
        <div className={classes.mainSkeletonContainer}>
          <div>
            <Skeleton
              style={{
                marginTop: "68px",
                padding: "0",
                width: "60vw",
                height: "50px",
                marginLeft: "20px",
              }}
            />
            <Skeleton
              style={{
                marginTop: "10%",
                padding: "0",
                width: "60vw",
                height: "70vh",
                marginLeft: "20px",
              }}
            />
          </div>
          <div>
            <Skeleton
              style={{
                marginTop: "4%",
                padding: "0",
                width: "30vw",
                height: "40vh",
                marginLeft: "20px",
              }}
            />
            <Skeleton
              style={{
                marginTop: "10%",
                padding: "0",
                width: "30vw",
                height: "30px",
                marginLeft: "20px",
              }}
            />
          </div>
        </div>
      ) : (
        <div className={classes.mainContainerU}>
          <div className={classes.mainContainer}>
            <div className={classes.left}>
              <Typography variant="h5" className={classes.myBag}>
                My Bag
              </Typography>

              <div className={classes.line} style={{ width: "100%" }}></div>
              {console.log("values", values)}
              {values && (
                <div className={classes.productsDiv} style={{ width: "100%" }}>
                  {values.map((item, i) =>
                    item.product ? (
                      <div style={{ width: "100%" }}>
                        <div className={classes.ProductContainer}>
                          <div style={{ display: "flex", flexDirection: "row" }}>
                            <div>
                              <img
                                src={item.product.images[0]}
                                alt=""
                                className={classes.ProductImage}
                                onClick={() => {
                                  history.push(
                                    `/buyproduct/${item.user}/${item.product.productId}`
                                  );
                                }}
                              />
                            </div>
                            <div className={classes.ProductTexts}>
                              <div
                                className={classes.ProductName}
                                onClick={() => {
                                  history.push(
                                    `/buyproduct/${item.user}/${item.product.productId}`
                                  );
                                }}
                              >
                                {item.product.productName}
                              </div>
                              <div
                                className={classes.ProductUser}
                                onClick={() => {
                                  history.push(`/profile/${item.user}`);
                                }}
                              >
                                by {item.user}
                              </div>
                              {/* <div>username {(auth.user.fullname)}</div> */}
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  gap: "5%",
                                  fontFamily: " 'Poppins', sans-serif",
                                  width: "100%",
                                }}
                              >
                                <div
                                  style={{
                                    color: "#333333",
                                    fontFamily: " 'Poppins', sans-serif",
                                    fontWeight: "300",
                                    letterSpacing: "1px",
                                  }}
                                  className={classes.cartDetailssText}
                                >
                                  Rs.
                                </div>
                                <div className={classes.cartDetailssTextProductPrice}>
                                  {item.product.price}
                                </div>
                                <div className={classes.cartDetailssTextProductMrp}>
                                  {item.product.mrp}
                                </div>
                              </div>

                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                  marginRight: "42%",
                                  width: "100%",
                                }}
                              >
                                <div className={classes.ProductDec}>
                                  <div className={classes.cartDetailssText}>Size </div>
                                  <div className={classes.cartDetailssText}>:</div>
                                </div>
                                <div
                                  style={{ textTransform: "capitalize" }}
                                  className={classes.cartDetailssText}
                                >
                                  {item.size}
                                </div>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                  marginRight: "30%",
                                  width: "100%",
                                }}
                              >
                                <div className={classes.ProductDec}>
                                  <div className={classes.cartDetailssText}>Colour</div>{" "}
                                  <div className={classes.cartDetailssText}>:</div>
                                </div>
                                <div className={classes.cartDetailssText}> {item.color}</div>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                  // marginRight: "30%",
                                }}
                              >
                                <div className={classes.ProductDec}>
                                  <div className={classes.cartDetailssText}> Qty</div>
                                  <div className={classes.cartDetailssText}>:</div>
                                </div>
                                <div>
                                  <TextField
                                    type="number"
                                    defaultValue={item.quantity}
                                    inputProps={{ min: 1 }}
                                    onChange={(e) => {
                                      handleChangeQuantity(
                                        item.product.productId,
                                        item.user,
                                        e.target.value,
                                        item.size
                                      );
                                    }}
                                    className={classes.ProductQty}
                                  />
                                </div>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                  // marginRight: "30%",
                                }}
                              >
                                <div className={classes.ProductDec}>
                                  <div className={classes.cartDetailssText}>Total</div>
                                  <div className={classes.cartDetailssText}>:</div>
                                </div>
                                <div className={classes.cartDetailssText}>
                                  Rs. {item.quantity * item.product.price}
                                </div>
                              </div>
                            </div>
                          </div>
                          <button
                            onClick={() =>
                              handleDelete(item.product.productId, item.user, item.size, item.color)
                            }
                            className={classes.DelButton}
                          >
                            <svg
                              width="40"
                              height="40"
                              viewBox="0 0 40 40"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <circle cx="20" cy="20" r="19.5" fill="white" stroke="#BEBEBE" />
                              <path
                                d="M16 13.6C16 12.7163 16.7163 12 17.6 12H22.4C23.2837 12 24 12.7163 24 13.6V15.2H25.5917C25.5967 15.2 25.6016 15.2 25.6066 15.2H27.2C27.6418 15.2 28 15.5582 28 16C28 16.4418 27.6418 16.8 27.2 16.8H26.3449L25.651 26.514C25.5912 27.3513 24.8945 28 24.0551 28H15.9449C15.1055 28 14.4088 27.3513 14.349 26.514L13.6551 16.8H12.8C12.3582 16.8 12 16.4418 12 16C12 15.5582 12.3582 15.2 12.8 15.2H14.3934C14.3984 15.2 14.4033 15.2 14.4083 15.2H16V13.6ZM17.6 15.2H22.4V13.6H17.6V15.2ZM15.2592 16.8L15.9449 26.4H24.0551L24.7408 16.8H15.2592ZM18.4 18.4C18.8418 18.4 19.2 18.7582 19.2 19.2V24C19.2 24.4418 18.8418 24.8 18.4 24.8C17.9582 24.8 17.6 24.4418 17.6 24V19.2C17.6 18.7582 17.9582 18.4 18.4 18.4ZM21.6 18.4C22.0418 18.4 22.4 18.7582 22.4 19.2V24C22.4 24.4418 22.0418 24.8 21.6 24.8C21.1582 24.8 20.8 24.4418 20.8 24V19.2C20.8 18.7582 21.1582 18.4 21.6 18.4Z"
                                fill="#333333"
                              />
                            </svg>
                          </button>
                        </div>
                        {/* <Divider variant="fullWidth" /> */}
                        <div className={classes.line}></div>
                      </div>
                    ) : (
                      <div></div>
                    )
                  )}
                </div>
              )}
              {/* <WishlistComponent /> */}
            </div>
            <div className={classes.right}>
              <div className={classes.rightMain}>
                <div className={classes.rightPriceMain}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      fontFamily: "Poppins, sans-serif",
                      gap: "5px",
                    }}
                  >
                    Price Detailss
                    <div style={{ fontFamily: "Poppins, sans-serif" }}>({values.length})</div>
                  </div>
                  <div
                    className={classes.line}
                    style={{ width: "65%", borderTop: "1px solid black" }}
                  ></div>
                </div>

                <div gutterBottom className={classes.rightTextDetailss}>
                  <div
                    className={classes.textValue}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      width: "48%",
                      fontFamily: "poppins, sens-serif",
                    }}
                  >
                    Order Value:
                  </div>
                  <div style={{ display: "flex", justifyContent: "center", width: "4%" }}>:</div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      width: "48%",
                      fontFamily: "poppins, sens-serif",
                    }}
                    className={classes.textValue}
                  >
                    {" "}
                    {total}
                  </div>
                </div>
                {/* <div gutterBottom className={classes.rightTextDetailss}>
                  <div style={{ display: "flex", justifyContent: "center", width: "48%", fontFamily: "poppins, sens-serif" }}>
                    discount
                  </div>
                  <div style={{ display: "flex", justifyContent: "center", width: "4%" }}>:</div>
                  <div style={{ display: "flex", justifyContent: "center", width: "48%", fontFamily: "poppins, sens-serif" }}>
                    {" "}
                    {total - discount}
                  </div>
                </div> */}

                <div
                  gutterBottom
                  className={classes.rightTextDetailss}
                  style={{ marginBottom: "3%" }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      width: "48%",
                      fontFamily: "poppins, sens-serif",
                    }}
                    className={classes.textValue}
                  >
                    delivery
                  </div>
                  <div style={{ display: "flex", justifyContent: "center", width: "4%" }}>:</div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      width: "48%",
                      fontFamily: "poppins, sens-serif",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                    className={classes.textValue}
                  >
                    {" "}
                    <div style={{ color: "red", fontWeight: "600" }}>Free</div>
                    <div style={{ textDecoration: "line-through" }}>₹ {delivery * seller}</div>
                  </div>
                </div>

                {/* <Divider /> */}
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div
                    className={classes.line}
                    style={{ width: "65%", borderTop: "1px solid black" }}
                  ></div>
                </div>
                <div gutterBottom className={classes.rightTextDetailss} style={{ marginTop: "2%" }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      width: "48%",
                      fontFamily: "poppins, sens-serif",
                    }}
                    className={classes.textValue}
                  >
                    Total
                  </div>
                  <div style={{ display: "flex", justifyContent: "center", width: "4%" }}>:</div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      width: "48%",
                      fontFamily: "poppins, sens-serif",
                    }}
                    className={classes.textValue}
                  >
                    {/* {total + delivery} */}
                    {total}
                  </div>
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <button
                    className={classes.btn1}
                    onClick={() => {
                      localStorage.setItem("totalAmount", total);
                      localStorage.setItem("totalDelivery", delivery);
                      localStorage.setItem("orderFrom", "cart");
                      localStorage.removeItem("user");
                      localStorage.removeItem("ID");
                      localStorage.removeItem("quantity");
                      localStorage.removeItem("color");
                      localStorage.removeItem("size");

                      window.location.href = `/payment`;
                      ReactGA.event({
                        category: "cart Payment btn",
                        action: "payment",
                        label: auth.user.username,
                      });
                    }}
                    // onClick={() => {
                    //   window.alert("please return here after launch");
                    // }}
                  >
                    Proceed to checkout
                  </button>
                </div>
              </div>

              <Divider />
            </div>
          </div>

          <button
            color="primary"
            disableElevation
            variant="contained"
            fullWidth
            className={classes.btn}
            onClick={() => {
              localStorage.setItem("totalAmount", total);
              localStorage.setItem("totalDelivery", delivery * seller);
              localStorage.setItem("orderFrom", "cart");
              localStorage.removeItem("user");
              localStorage.removeItem("ID");
              localStorage.removeItem("quantity");
              localStorage.removeItem("color");
              localStorage.removeItem("size");
              window.location.href = `/payment`;
              ReactGA.event({
                category: "cart Payment btn",
                action: "payment",
                label: auth.user.username,
              });
            }}
            // onClick={() => {
            //   window.alert("please return here after launch");
            // }}
          >
            Proceed to checkout
          </button>

          <div>{/* sadf */}</div>
        </div>
      )}
    </div>
  );
};

export default Cart;
